.floatingCart {
  position: fixed;
  top: 50%;
  z-index: 11;
  right: 105px;
}

.cartClosed {
  cursor: pointer;
  padding: 8px 16px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  width: fit-content;
  position: relative;
}
.cartClosed h2 {
  position: absolute;
  top: -15px;
  right: -15px;
  color: #ffffff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  background-color: #003366;
}
.cartOpened {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  border: 1px solid #003366;
}

.cartOpened button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.totalPrice {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.cartOpened .checkout-icon {
  font-size: 16px;
  text-decoration: none;
  margin-top: 5px;
  cursor: pointer;
}
