.header {
  margin-top: 60px;
  padding: 0 40px;
  background-color: #fdc500;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logout__link {
  flex: 1;
}

.logout__link .logout__link--btn {
  width: fit-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.header .search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.search input {
  display: none;
  width: 360px;
  font-size: 16px;
  border: 1px solid #003366;
  outline: none;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s;
}
.clearSearch {
  transform: translateY(2px);
  cursor: pointer;
  transition: 0.2s;
  display: none;
}

.openSearch {
  transform: translateY(2px);
  cursor: pointer;
}
.header__logo {
  width: 125px;
  height: 111px;
  background: #fdc500;
  /* modal shadow */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
  padding: 24px;
}
.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
