.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  /* height: 200px; */
  background: #003366;
  position: relative;
}
.footer h3 {
  /* width: 541px; */
  /* height: 29px; */

  /* H3 Bold 24 (0.3 px) */

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: 0.3px;

  /* grayscale / white */

  color: #ffffff;
}

.footer h4 {
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;

  /* grayscale / white */

  color: #ffffff;
}

.footer__inner {
  text-align: center;
}

.footer__logo {
  width: 78px;
  height: 63px;
  object-fit: cover;
  position: absolute;
  left: 8%;
}
