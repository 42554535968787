.main {
  position: relative;
}
.shopPageOne {
  margin-top: 56px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Shop v1 card page */

.cardOne {
  width: calc(33% - 24px);
  background-color: #ffffff;
  border-radius: 8px;
  transition: 0.2s;
}

.bg__lines__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.bg__line2__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.bg__lines {
  position: relative;
  height: 100vh;
  width: 100%;
}

.cardOne__top {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 45px 0;
  position: relative;
}
.cardOne__hour__wrapper {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  padding: 13px;
  background: linear-gradient(135deg, #ffffff 100%, #ffffff 50%);
  box-shadow: -15px 15px 30px rgba(191, 181, 213, 0.2),
    15px -15px 30px rgba(191, 181, 212, 0.2),
    -15px -15px 30px rgba(255, 255, 255, 0.9),
    15px 15px 38px rgba(230, 230, 230, 0.9), inset -8px -13px 16px #ffffff,
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(205, 205, 205, 0.5);
}
.cardOne__hour__border {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardOne__hour {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 4px;
}
.cardOne__hour h3 {
  font-size: 80px;
  position: relative;
}
.cardOne__hour h3::after {
  content: "HOURS";
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: 0;
}
.cardOne__bottom {
  transition: 0.2s;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}
.cardOne:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 10px 2px #eeeeee;
}
.cardOne:hover .cardOne__bottom {
  background-color: #fdc500;
}

.cardOne:hover .cardOne__btn * {
  color: #fdc500 !important;
}

.cardOne__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cardOne__info {
  font-size: 14px;
  /* height: 60px; */
  text-overflow: ellipsis;
}
.cardOne__price {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardOne__price h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.cardOne__price > .itemCounter {
  display: flex;
  align-items: center;
  gap: 25px;
}
.cardOne__price > .itemCounter > p {
  font-weight: 700;
  font-size: 16px;
}
.itemCounter > button {
  background: transparent;
  border: none;
  width: 20px;
  height: fit-content;
}
.itemCounter > button > * {
  transform: translateY(2px) !important;
}

.cardOne__btn,
.cardTwo__btn,
.cardOne__btn__remove {
  width: 100%;
  padding: 8px 32px;
  height: 45px;
  background: #003366;
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  cursor: pointer;
  margin-top: 24px;
}

.cardOne__btn *,
.cardTwo__btn *,
.cardOne__btn__remove * {
  color: #ffffff !important;
}
.cardOne__btn > div,
.cardTwo__btn > div,
.cardOne__btn__remove > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cartPriceCounter span {
  font-size: 18px;
}

.limitedOffer__container {
  margin-top: 200px;
}
.limitedOffer__header {
  background-color: #fdc500;
  border-radius: 0px 200px 200px 0px;
  padding: 40px 100px 40px 176px;
  width: fit-content;
}
.limitedOffer__header h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1px;
  color: #003366;
}

.limitedOffer__items {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;
}

.extraHours {
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  /* identical to box height */

  letter-spacing: 0.4px;

  /* green / default */
  color: #29cc97;
}

.cardTwo__bottom {
  transition: 0.2s;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.cardOne:hover .cardTwo__bottom {
  background-color: #29cc97;
}
.cardOne:hover .cardTwo__btn * {
  color: #29cc97 !important;
}

/* We will not need this if we remove empty limitedOffer section if there are not limitedOffer products */
.limitedOffer__empty {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.88) -8.42%,
    rgba(255, 255, 255, 0) 78.01%
  );
  filter: drop-shadow(0px 4px 56px rgba(0, 51, 102, 0.16));
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 176px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  border-radius: 16px;
}
.limitedOffer__empty .empty__icon svg {
  font-size: 120px;
}
.limitedOffer__empty .empty__title h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1px;

  /* accent/default */

  color: #003366;
}

/* /////////////////////////// */
.limitedCardOne {
  position: relative;
}

.limitedCardOne:hover .cardOne__bottom {
  background-color: #29cc97 !important;
}

.limitedCardOne .limitedCardOne__hours {
  position: absolute;
  width: 173px;
  height: 23px;
  left: 20px;
  top: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* green / default */
  color: #29cc97;
}

.limitedCardOne:hover .cardOne__btn * {
  color: #29cc97 !important;
}
