*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #003366;
}

body {
  font-family: 'Inter',sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fc;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  cursor: pointer;
  border: none;
}

a{
  text-decoration: none;
}

@media screen and (max-width: 1440px) {
    .container{
      padding: 0 83px !important;
    }
    .floatingCart{
      right: 60px !important;
    }
    .header{
      margin-top: 40px !important;
      padding: 0 30px !important;
    }
    .cardOne{
      width: calc(50% - 24px) !important;
    }
}

@media screen and (max-width: 768px) {
  .container{
    padding: 0 20px !important;
  }
  .limitedOffer__container{
    margin-top: 100px !important;
  }
  .limitedOffer__header{
    padding: 12px 24px !important;
  }
  .limitedOffer__header h1{
    font-size: 18px !important;
  }
  .shoppingCartOne__title{
    font-size: 24px !important;
  }
  .shoppingCartOne__header{
    margin-top: 24px !important;
    padding: 16px 8px !important;
    gap: 8px !important;
  }
  .shoppingCartOne__header h4{
    /* overflow: hidden; */
    width: fit-content;
    font-size: 12px !important;
  }
  .footer{
    gap: 20px;
    /* height: 120px !important; */
    padding: 24px !important;
    flex-direction: column-reverse !important;
  }
  .footer h3{
    font-size: 14px !important;
  }
  .footer__logo{
    position: relative !important;
    left: 0 !important;
    width: 48px !important;
    height: 48px !important;
    /* left: 20px !important; */
    /* bottom: 10px ; */
  }
  .floatingCart{
    top: 100px !important;
    right: 20px !important;
  }
  .shoppingCartOne__bottom{
    flex-direction: column-reverse !important;
    gap: 24px;
  }
  .sco__itemCard{
    padding: 8px !important;
  }
  .sco__itemInfo{
    gap: 8px !important;
    align-items: center !important;
  }
  .sco__image{
    width: 24px !important;
    height: 24px !important;
  }
  .sco__info{
    max-width: 120px !important;
  }
  .sco__title{
    font-size: 14px !important;
    line-height: 14px !important;
  }
  .sco__description{
    display: none;
  }
  .itemCounter{
    gap: 8px !important;
  }
  .itemCounter p{
    font-size: 14px !important;
  }
  .sco__price h2{
    font-size: 14px !important;
  }
  .header{
    height: 48px !important;
    margin-top: 20px !important;
    padding: 0 20px !important;
  }
  .header__logo{
    width: 64px !important;
    height: 64px !important;
    padding: 8px !important;
  }
  .search input{
    position: absolute;
    width: 300px !important;
    top: 50%;
    right: 0; 
  }
  .homepage__title{
    margin-top: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }
  .shopPageOne{
    margin-top: 24px !important;
  }
  .cardOne{
    width: calc(100%) !important;
  }
  .login{
    padding: 0 20px !important;
  }

  .login__verification{
    width: 100% !important;
    padding: 1.5em !important;
  }
  .login__verification__container{
    padding: 0 !important;
    width: 100% !important;
    align-items: center !important;
  }
  .stripe__child::after{
    height: 80px !important;
  }
  .stripe1{
    height: 360px !important;
    width: 360px !important;
    top: -150px !important;
    left: -150px !important;
  }
  .stripe2{
    height: 120px !important;
    width: 120px !important;
    top: -60px !important;
    left: 100px !important;
  }
  .stripe3{
    height: 150px !important;
    width: 150px !important;
    top: 100px !important;
    left: -70px !important;
  }
  .stripe4{
    height: 150px !important;
    width: 150px !important;
    top: 300px !important;
    left: -70px !important;
  }
  .stripe5{
    height: 360px !important;
    width: 360px !important;
    bottom: -150px !important;
    right: -150px !important;
  }
  .stripe6{
    height: 120px !important;
    width: 120px !important;
    bottom: -40px !important;
    right: 120px !important;
  }
  .stripe7{
    height: 120px !important;
    width: 120px !important;
    bottom: 120px !important;
    right: -40px !important;
  }
  .stripe__round{
    width: 80px !important;
    height: 80px !important;
    top: 60px !important;
    left: 220px !important;
  }
  .payment{
    padding: 0 20px !important;
  }
  .payment__container{
    width: 100% !important;
    padding: 32px !important;
  }
  .continueShopping{
    flex-direction: column;
  }
  .continueShopping button{
    /* width: 360px !important; */
    padding: 10px 20px !important;
  }

  .cardOne__top{
    padding: 32px 0 !important;
  }

  .cardOne__hour__wrapper{
    width: 150px !important;
    height: 150px !important;
  }
  .cardOne__hour h3{
    font-size: 40px !important;
  }
  .cardOne__hour h3::after{
    bottom: -8px !important;
    font-size: 12px !important;
  }
  .extraHours{
    font-size: 12px !important;
  }
}


