.shoppingCartOne {
  /* position: relative; */
  margin-bottom: 70px;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  margin-top: 48px;
}

.shoppingCartOne__wrapper {
  background-color: #ffffff;
  position: relative;
}

.shoppingCartOne__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1px;

  /* accent/default */

  color: #003366;
}

.shoppingCartOne__header {
  margin-top: 50px;
  display: grid;
  grid-template-columns: max-content 110px 130px;
  gap: 95px;
  align-items: center;
  padding: 24px;
  background: #003366;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 24 px;
}

@media screen and (min-width: 1700px) {
  .shoppingCartOne__header {
    gap: 120px;
  }
}
@media screen and (min-width: 1800px) {
  .shoppingCartOne__header {
    gap: 173px;
  }
}

.shoppingCartOne__header--left {
  display: grid;
  grid-template-columns: 176px 430px;
  gap: 32px;
}

.shoppingCartOne__header h4 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  justify-self: center;
}

.shoppingCartOne__items {
  max-height: 70vh;
  overflow-y: auto;
}

.shoppingCartOne__items::-webkit-scrollbar {
  width: 4px;
  background-color: #dfe0eb;
}

.shoppingCartOne__items::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #003366;
}

.shoppingCartOne__bottom {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.continueShopping {
  display: flex;
  align-items: center;
  gap: 32px;
}

.continueShopping button {
  background-color: #003366;
  color: #fdc500;
  padding: 10px 70px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
}
.continueShopping button span {
  transform: rotateZ(180deg);
}
.continueShopping button span * {
  color: #fdc500 !important;
  transform: translateY(1px);
}

.subtotalCard {
  min-width: 210px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px;
}
.subtotalCard div {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
.subtotalCard div:last-of-type {
  border-top: 1px solid #003366;
  border-bottom: 1px solid #003366;
}
.subtotalCard div h4 {
  display: flex;
  align-items: center;
}
.subtotalCard div h4 svg {
  font-size: 16px;
  transform: translateY(1px);
}
/* ShoppingCart Product Card */

.sco__itemCard {
  display: grid;
  grid-template-columns: max-content 110px 130px 1fr;
  gap: 95px;
  align-items: center;
  border-bottom: 1px solid #003366;
  padding: 24px;
}

@media screen and (min-width: 1700px) {
  .sco__itemCard {
    gap: 120px;
  }
}

@media screen and (min-width: 1800px) {
  .sco__itemCard {
    gap: 173px;
  }
}

.sco__itemInfo {
  display: grid;
  grid-template-columns: 176px 430px;
  gap: 32px;
}

.sco__image {
  width: 176px;
  height: 176px;
  border-radius: 8px;
}

.sco__image img {
  width: 100%;
  height: 100%;
}

.sco__info {
  max-width: 440px;
}
.sco__title {
  /* H1 Bold 40 (1 px) */

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 1px;

  /* accent/default */

  color: #003366;
}
.sco__description {
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.3px;

  /* accent/default */

  color: #003366;
}

.itemCounter {
  display: flex;
  justify-content: space-between;
  justify-self: center;
  min-width: 110px;
}
.itemCounter p {
  font-size: 20px;
  font-weight: 700;
}

.sco__price {
  justify-self: center;
}
.sco__price h2 {
  display: flex;
  align-items: center;
  gap: 4px;
}
.sco__removeBtn {
  background: transparent;
  border: none;
  justify-self: right;
}

.disabled-btn {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #003366b7 !important;
}

.disabled-checkout-btn {
  cursor: not-allowed;
  pointer-events: none;
  color: #003366b7 !important;
}
