.homepage__inner{
    /* padding: 0 176px; */
}
.homepage__title{
    margin-top: 56px;
    font-size: 40px;
line-height: 48px;
letter-spacing: 1px;

/* accent/default */

color: #003366;
}
.container{
    padding: 0 176px;
}